import React, { Component } from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import ProductsList from "./products/ProductsList";
class Home extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>BonusReview.us - Best Bonuses For You.</title>
          <meta property="og:title" content="BonusReview.us - Best Bonuses For You." />
          <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/media/og.png`} />
        </Helmet>
        <ProductsList />
      </>
    );
  }
}

export default Home;
