import React, { Component } from "react";
import Root from "./Root";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./components/layout";
import Home from "./components/Home";
import ReviewList from "./components/reviews/ReviewList";
import AllReviews from "./components/reviews/AllReviews";
import Blog from "./components/Blog";
import UserProductsList from "./components/users/UserProductsList";
import Signup from "./components/account/Signup";
import Login from "./components/login/Login";
import ResendActivation from "./components/account/ResendActivation";
import ActivateAccount from "./components/account/ActivateAccount";
import ResetPassword from "./components/account/ResetPassword";
import ResetPasswordConfirm from "./components/account/ResetPasswordConfirm";

import Dashboard from "./components/dashboard/Dashboard";

import requireAuth from "./utils/RequireAuth";

import axios from "axios";
const dotenv = require('dotenv');
dotenv.config({ path: '../.env' });

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function App() {

  // render() {
  // const { user } = this.props.auth;
  return (
    <div>
      <Root>
        <Layout>
          <ToastContainer hideProgressBar={true} newestOnTop={true} />
          <Switch>
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={requireAuth(Dashboard)} />
            <Route path="/blog" component={Blog} />
            <Route exact path="/" component={Home} />
            <Route path="/resend_activation" component={ResendActivation} />
            <Route path="/activate/:uid/:token" component={ActivateAccount} />
            <Route path="/send_reset_password/" component={ResetPassword} />
            <Route path="/product/:slug" component={ReviewList} />
            <Route path="/vendors-and-affiliates/:slug" component={UserProductsList} />
            <Route path="/reviews" component={AllReviews} />
            <Route
              path="/reset_password/:uid/:token"
              component={ResetPasswordConfirm}
            />
          </Switch>
        </Layout>
      </Root>
    </div>
  );
  // }
}

// const mapStateToProps = state => ({
//     auth: state.auth,
// });

// export default connect(mapStateToProps, {
//     getAffiliates
// })(withRouter(AffiliateList));
// export default connect(mapStateToProps)(withRouter(App));
export default App;
