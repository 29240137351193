import React from 'react';
import { Container } from "react-bootstrap";
import BNavbar from "./Navbar";

const Layout =({children}) =>{
    return(
        <>
        <div>
            <BNavbar />
            <Container >
            <main>{children}</main>
            </Container>
        </div>
        </>
    )
}

export default Layout;