import axios from "axios";

export async function getUserProducts(slug) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/vendors-and-affiliates/${slug}/`);
        let result = await response.data;
        return result;
    } catch (error) {
        return [];
    }
}