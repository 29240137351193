import React, { useState } from "react";
import Moment from 'react-moment';
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Product({ product, user }) {

  const [p_status, setStatus] = useState(product.status);


  var product_image = {
    height: "155px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `url("${process.env.REACT_APP_API_URL}/media/default-image.png")`,
  };

  if (product.thumbnail) {
    product_image.backgroundImage = `url("${process.env.REACT_APP_API_URL}${product.thumbnail}")`;
  }

  const HandleProductChange = (e) => {
    let data = {
      "status": e.target.checked,
      "id": product.id,
      "type": "product"
    }
    // axios.post(`${process.env.REACT_APP_API_URL}/api/v1/change_status`, data)
    axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/handle/`, data)
      .then(res => {
        setStatus(res.data.status);
      });
  }

  const cardStyle = () => {
    if (user.admin && p_status == 2) {
      return {
        "backgroundColor": "#f8d7da",
      }
    }
    return {};
  }

  const renderBigOrAll = (section) => {
    if (user.admin) {
      if (section == "Big") {
        return "[B]"
      }
    }
    return ""
  }


  const vendor_image = product.vendor.image ? product.vendor.image : `${process.env.REACT_APP_API_URL}/media/d-vendor.png`;
  return (
    <div className="col-md-4 col-lg-3" >
      {(user.admin) ?
        <div className="d-flex align-items-center justify-content-between p-1 p-card-background" >
          <Link to={`/admin/bonus_hunter/product/${product.id}/change/`} target="_blank">
            <FontAwesomeIcon
              icon={faWrench}
              size="lg"
              className="mr-1"
            />
            Edit
          </Link>

          <h5 className="m-0 small text-uppercase font-weight-normal"><Moment fromNow>{product.launch_date}</Moment></h5>


          <input
            type="checkbox"
            defaultChecked={product.status == 1}
            style={{ transform: 'scale(1.5, 1.5) translateX(-50%)' }}
            onChange={(e) => HandleProductChange(e)} />
        </div>

        :
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div></div>
            <div className="time-on-top p-card-background">
              <h5 className="m-0 small text-uppercase font-weight-normal"><Moment fromNow>{product.launch_date}</Moment></h5>
            </div>
          </div>
        </>
      }
      <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/product/${product.slug}`}>
        {/* <Card className="mb-5" style={cardStyle()}> */}
        <Card className="mb-5 product" style={cardStyle()}>
          {/* <div id="product_image" style={product_image}></div> */}
          {/* <Card.Img variant="top" className="h-100" src={product_image} /> */}
          <Card.Header className="p-card-background" >
            <Card.Title className="text-capitalize mb-0" >{product.name} {renderBigOrAll(product.section)}</Card.Title>
          </Card.Header>
          <Card.Body className="pr-3 pl-3 pt-2">
            {/* <Card.Text> */}
            <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/vendors-and-affiliates/${product.vendor.slug}`}>
              <div className="vendor mt-2">
                {/* <img className="vendor_image rounded-circle mr-2" src={vendor_image} alt={product.vendor.name} /> */}
                <p className="vendor_name text-capitalize">{product.vendor.name}</p>
              </div>
            </Link>

            {/* circle avatars from list */}
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div className="affiliates_images">
                {product.affiliate_images.map((affiliate, index) => {
                  return (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="button-tooltip-2" class="text-capitalize">{affiliate.name}</Tooltip>}
                    >
                      <Link key={affiliate.id} to={`/vendors-and-affiliates/${affiliate.slug}`} target="_blank">
                        <img
                          className="product_affiliate_images rounded-circle mr-1"
                          src={affiliate.image}
                          alt={affiliate.name}
                          onError={(e) => {
                            e.target.src = `${process.env.REACT_APP_API_URL}/media/d-affiliate.png` // some replacement image
                          }} />
                      </Link>
                    </OverlayTrigger>
                  );
                })}
              </div>

              <p className="m-0">{product.affiliate_review_count} Affiliates</p>

            </div>


            {/* </Card.Text> */}

            <div className="d-flex align-items-center justify-content-between mt-2">
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div>
                  {product.category.length > 0 &&
                    <span className="h6 small bg-secondary p-1 mr-1 text-white rounded">
                      {product.category[0]}
                    </span>
                  }

                  {product.type &&
                    <span className="h6 small p-1 text-white rounded bright_green">
                      {product.type}
                    </span>
                  }
                </div>
              </div>
              <h5 className="m-0">${product.price}</h5>
            </div>

            {/* </Card.Text> */}
          </Card.Body>
        </Card>
      </Link>
    </div>
  );
  // }
}

export default Product;


// Product.propTypes = {
//   product: PropTypes.object.isRequired
// };
// const mapStateToProps = state => ({});

// export default connect(mapStateToProps, {})(
//   withRouter(Product)
// );
