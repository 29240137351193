import axios from "axios";

export async function getReviews(slug) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/reviews/${slug}`);
    let result = await response.data;
    return result;
  } catch (error) {
    return [];
  }
}

export async function getAllReviews(page = 1, ordering = "-created_at") {
  try {
    // ordering = ordering.endsWith("launch_date") ? "-status__name," + ordering : ordering;
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/reviews/?page=${page}&ordering=${ordering}`);
    let result = await response.data;
    return result;
  } catch (error) {
    return [];
  }
}

export async function saveProductImage(data) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/save_product_image`, data);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function takeScreenshot(id, type="pagescreen") {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/screenshot?link_id=${id}&type=${type}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function changeImageSource(id, val) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/change_image_source?link_id=${id}&val=${val}`);
    return response.data;
  } catch (error) {
    return [];
  }
}