import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive" style={{ textAlign: "center" }}>
    <iframe
      width={window.innerWidth > 768 ? "560" : "100%"}
      height="308"
      align="middle"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      title="Embedded youtube"
    />
  </div>
);

const getYoutubeVidId = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && Number(match[7].length) === 11) ? match[7] : false;
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

getYoutubeVidId.propTypes = {
  url: PropTypes.string.isRequired
};

export { YoutubeEmbed, getYoutubeVidId };