import React, { Component } from "react";
class Blog extends Component {
    render() {
        return (
            <div id="qb-posts"></div>
        );
    }
}

export default Blog;
