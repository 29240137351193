import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import ReviewList from './ReviewList';
import { getAllReviews } from "./ReviewServices";

function AllReviews() {

  const [page, setPage] = useState(1);
  const [hasmore, setHasmore] = useState(true);
  const [reviews, setReviews] = useState([]);


  const fetchData = async () => {
    let o = '-created_at';
    await getAllReviews(page, o).then((data) => {
      setReviews([...reviews, ...data.results]);
      if (!data.next) {
        setHasmore(false);
      }
    });
    setPage(page + 1);
  };

  useEffect(() => {
    console.log('useEffect');
    fetchData();
  }, [])

  return (
    <InfiniteScroll
      dataLength={reviews.length} //This is important field to render the next data
      next={() => {
        fetchData();
      }}
      hasMore={hasmore}
      loader={<Skeleton count={2} />}
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {reviews &&
        <ReviewList match={{ params: { reviews: reviews, slug: null } }} />
      }
    </InfiniteScroll>
  );
}

export default AllReviews;