import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "react-bootstrap";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import InfiniteScroll from "react-infinite-scroll-component";

// import { getProducts } from "./ProductsActions";
import { getProducts } from "./ProductServices";
import Product from "./Product";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import Sticky from 'react-sticky-el';


import './products.css';

function ProductsList() {

  const user = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [hasmore, setHasmore] = useState(true);
  const available_orders = useState([{ key: 'launch_date', label: "Recency" }, { key: 'name', label: "Product" }, { key: 'vendor__name', label: "Vendor" }, { key: 'affiliate_review_count', label: <><span id='hide-mobile'>Affiliate </span>Count</> }, { key: 'price', label: "Price" }])
  const [order, setOrder] = useState({ by: 'launch_date', order: 'desc' });


  useEffect(() => {
    fetchData();
  }, [order])

  const fetchData = async () => {
    let o = order.order === 'asc' ? order.by : '-' + order.by;
    await getProducts(page, o).then((data) => {
      setProducts([...products, ...data.results]);
      if (!data.next) {
        setHasmore(false);
      }
    });
    setPage(page + 1);
  };

  const ResetAndSetOrderBy = async (o) => {
    setProducts([]);
    setPage(1);
    setHasmore(true);
    setOrder({ by: o.key, order: o.key === order.by ? (order.order === 'asc' ? 'desc' : 'asc') : 'asc' });
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 p-0">
          <Sticky stickyClassName={"product-sticky"}>
            <div className='d-flex justify-content-between p-3 align-items-center' >
              <Link to={`/`}>
                <img src={`${process.env.REACT_APP_API_URL}/media/logo.png`}
                  className='animated-logo-on-sticky mr-3'
                  height="60"
                />
              </Link>
              <div id='home-sort-buttons'>
                <div className="btn-group btn-group-sort flex align-items-center" role="group" aria-label="Sort Buttons">
                  <span className='mr-2' id='home-sort-buttons-label' >Sort By</span>
                  {available_orders[0].map((orders) => {
                    return (
                      <Button className='d-flex align-items-center' key={orders.key} active={order.by === orders.key} variant="light" onClick={() => ResetAndSetOrderBy(orders)}>
                        <span className='mr-1'>{orders.label}</span>
                        {
                          (order.by === orders.key) ?
                            (order.order === 'asc') ?
                              <FontAwesomeIcon icon={faSortUp} size="xs" /> :
                              <FontAwesomeIcon icon={faSortDown} size="xs" />
                            : null
                        }
                      </Button>
                    )
                  })}
                  <span>
                  </span>
                </div>
              </div>
            </div>
          </Sticky>
        </div>
      </div>



      <div className="row">
        {products.length === 0 &&
          <div className="col-md-12">
            <Skeleton count={2} />
          </div>
        }
        <InfiniteScroll
          dataLength={products.length} //This is important field to render the next data
          next={() => {
            fetchData();
          }}
          hasMore={hasmore}
          loader={<Skeleton count={2} />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div className="row">
            {products.map((product) => (
              <Product key={product.id} product={product} user={user} />
            ))}
          </div>
        </InfiniteScroll>

      </div>
    </>
  );
}
export default ProductsList;
