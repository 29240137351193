// React Navbar
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { logout } from "./login/LoginActions";
class BNavbar extends Component {

    onLogout = () => {
        this.props.logout();
    };


    render() {
        const { user } = this.props.auth;

        const authStyle = { color: 'black', textDecoration: 'inherit' };

        let login_data = <Link style={authStyle} to='/login'>Login</Link>
        if (user.username) {
            login_data = (<>
                <Navbar.Text style={authStyle}>
                    Hi, <b>{user.username}</b>
                </Navbar.Text>
                <Nav.Link onClick={this.onLogout} style={authStyle}>Logout</Nav.Link>
            </>)
        } else {
            login_data = <Link style={authStyle} to='/login'>Login</Link>
        }

        return (
            <Navbar expand="lg" id="b_navbar" className="mb-2">
                <Container>
                    <Navbar.Brand href="#home">
                        <Link to={`/`} className="d-flex align-items-center" >
                            <img
                                id="logo"
                                src={`${process.env.REACT_APP_API_URL}/media/logo.png`}
                                height="80"
                                className="d-inline-block align-top"
                                alt="React Bootstrap logo"
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="justify-content-end" style={{ width: "100%" }}>
                            {login_data}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

BNavbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {
    logout
})(withRouter(BNavbar));
