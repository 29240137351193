import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

// eslint-disable-next-line 
import Card from 'react-bootstrap/Card';
import { Button } from "react-bootstrap";
// eslint-disable-next-line 
import ShowMoreText from "react-show-more-text";
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCut, faLink, faTrash, faImage, faWrench, faFileImage } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { getYoutubeVidId } from "../../utils/YoutubeEmbed";

import "./review.css";

function Review({ review, user, handleAdminPopupShow, setReviewIdChild, setShowUserModal, setSelectedReview, removeOtoImage, setSelectedAdminLink, setImageAdded }) {

  const [a_status, setReviewStatus] = useState(review.status);
  const [link_id, setActiveLinkId] = useState(null);

  useEffect(() => {
    setReviewStatus(review.status);
    // setActiveLinkId(review.affiliate_link_set[0].id);
    FindLinkId();
  }, [review])


  const FindLinkId = () => {
    for (let i = 0; i < review.affiliate_link_set.length; i++) {
      // console.log(links[i].status);
      if (review.affiliate_link_set[i].status === 1) {
        setActiveLinkId(review.affiliate_link_set[i].id);
      }
    }
  }

  const HandleLinkChange = (id, link) => {

    let data = {
      "status": true,
      "id": id,
      "review_id": review.id,
      "type": "link"
    }
    axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/handle/`, data).then(res => {
      setActiveLinkId(id);
      if (Number(a_status) === 4) {
        setReviewStatus(1);
      }
    })
  }

  const DeleteOTOImage = () => {
    let data = {
      "id": review.id,
      "type": "delete_oto"
    }
    axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/handle/`, data).then(res => {
      removeOtoImage(review.id);
    });
  }


  const handleAdminPopup = (object) => {
    setSelectedAdminLink(object)
    setImageAdded(object.image_added ? "system" : "pagescreen")
    setReviewIdChild(review.id);
    handleAdminPopupShow(object.id);
  }

  const handleUserPopup = () => {
    setSelectedReview(review);
    setShowUserModal(true);
  }


  var affiliate_image = {
    height: "200px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  if (review.affiliate.channel_thumbnail) {
    affiliate_image.imageurl = review.affiliate.channel_thumbnail;
    affiliate_image.backgroundImage = `url("${review.affiliate.channel_thumbnail}")`;
  } else {
    affiliate_image.imageurl = `${process.env.REACT_APP_API_URL}/media/d-affiliate.png`;
    affiliate_image.backgroundImage = `url("${process.env.REACT_APP_API_URL}/media/d-affiliate.png")`;
  }

  // get url pathname from a given url
  const getLinkPortion = (url) => {
    const urlObject = new URL(url);
    return urlObject.pathname;
  }


  function renderLink(object, i) {
    if (user.admin) {
      // if (object.image_added) {/
      return (<>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div >
            <input type="radio" id={object.id} checked={Number(object.id) === Number(link_id)} onChange={() => HandleLinkChange(object.id, object.link)} />
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip-2" class="text-capitalize">{object.link}</Tooltip>}
            >
              <label for={object.id}>{getLinkPortion(object.link)}</label>
            </OverlayTrigger>
            {/* <label for={object.id}><div>Get Bonuses From</div> <div>{review.affiliate.name}</div></label> */}
          </div>
          {object.image_added &&
            <a href={`${process.env.REACT_APP_API_URL}/media/screenshots/${object.id}_screenshot.jpg`} target="_blank" rel="noreferrer" role="button">
              <FontAwesomeIcon icon={faImage} color="blue" role="button" />
            </a>
          }
          <a href={object.image_link} target="_blank" rel="noreferrer" role="button">
            <FontAwesomeIcon icon={faFileImage} color="blue" role="button" />
          </a>
          <FontAwesomeIcon icon={faCut} color="blue" onClick={() => handleAdminPopup(object)} role="button" />
          <a href={object.link} target="_blank" rel="noreferrer" role="button">
            <FontAwesomeIcon icon={faLink} color="blue" />
          </a>
        </div>
      </>)
      // } else {
      // return (<>
      //   <div className="d-flex align-items-center justify-content-between">
      //     <div >
      //       <input type="radio" id={object.id} checked={Number(object.id) === Number(link_id)} onChange={() => HandleLinkChange(object.id, object.link)} />
      //       <label for={object.id}>{getLinkPortion(object.link)}</label>
      //       {/* <label for={object.id}><div>Get Bonuses From</div> <div>{review.affiliate.name}</div></label> */}
      //     </div>
      //     <a href={object.link} target="_blank" rel="noreferrer" role="button">
      //       <FontAwesomeIcon icon={faLink} color="blue" />
      //     </a>
      //   </div>
      // </>)
      // }
    }
  }


  const HandleReviewChange = (e) => {
    let data = {
      "status": e.target.checked,
      "id": review.id,
      "type": "review"
    }
    // axios.post(`${process.env.REACT_APP_API_URL}/api/v1/change_status`, data)
    axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/handle/`, data)
      .then(res => {
        setReviewStatus(res.data.status);
      });
  }

  const cardStyle = () => {
    if (user.admin && Number(a_status) === 2) {
      return {
        "backgroundColor": "#f8d7da",
      }
    } else if (user.admin && Number(a_status) === 4) {
      return {
        "backgroundColor": "#fff276",
      }
    } else if (user.admin && review.oto_image) {
      return {
        "backgroundColor": "#d4edda",
      }
    }
    return {};
  }

  return (
    <div className="col-md-6 col-lg-4" style={{ zIndex: "1" }}>
      {(user.admin) ?
        <div className="d-flex align-items-center justify-content-between p-1 p-card-background" >
          <Link to={`/admin/bonus_hunter/affiliate_product_review/${review.id}/change/`} target="_blank">
            <FontAwesomeIcon
              icon={faWrench}
              size="lg"
              className="mr-1"
            />
            Edit
          </Link>

          <h5 className="m-0 small text-uppercase font-weight-normal">
            <Moment fromNow>{review.created_at}</Moment></h5>


          {/* <input
            type="checkbox"
            defaultChecked={product.status == 1}
            style={{ transform: 'scale(1.5, 1.5) translateX(-50%)' }}
            onChange={(e) => HandleProductChange(e)} /> */}
        </div>

        :
        null
      }
      {/* <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to={`/product/${product.id}`}> */}
      <Card className="mb-5" style={cardStyle()}>
        {/* <div style={ affiliate_image }></div> */}
        {/* <div className="d-flex justify-content-center mt-1">
            <img src={affiliate_image.imageurl} height={200} width={200}/>
          </div> */}
        <div className="card-header d-flex align-items-center justify-content-between">
          {user.admin ?
            <>
              <Link target="_blank" style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/vendors-and-affiliates/${review.affiliate.slug}`} className="d-flex align-items-center">
                <img
                  className="affiliate_image rounded-circle mr-3"
                  src={affiliate_image.imageurl}
                  alt={review.affiliate.name}
                />
                {/* <p className="review_name">{review.affiliate.name}</p> */}
                <Card.Title className="m-0 flex align-items-center justify-content-between text-capitalize mr-2"><span>{review.affiliate.name}</span></Card.Title>
              </Link>
              <input type="checkbox" style={{ transform: 'scale(1.5, 1.5) translateX(-50%)' }} checked={Number(a_status) === 1} onChange={(e) => HandleReviewChange(e)} />
            </>
            :
            <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/vendors-and-affiliates/${review.affiliate.slug}`} className="d-flex align-items-center">
              <img
                className="affiliate_image rounded-circle mr-3"
                src={affiliate_image.imageurl}
                alt={review.affiliate.name}
              />
              {/* <p className="review_name">{review.affiliate.name}</p> */}
              <Card.Title className="m-0 flex align-items-center justify-content-between text-capitalize mr-2"><span>{review.affiliate.name}</span></Card.Title>
            </Link>

          }


        </div>
        <Card.Body className="pt-1">

          {/* <h6>{review.video_title}</h6> */}
          {/* <YoutubeEmbed embedId={youtube_parser(review.video_link)} /> */}
          {/* <img onClick={() => handleUserPopup()} src={review.thumbnail} alt={review.affiliate.name} style={{ maxHeight: "100%", maxWidth: "100%" }} /> */}
          <img onClick={() => handleUserPopup()} src={`https://i.ytimg.com/vi/${getYoutubeVidId(review.video_link)}/mqdefault.jpg`} alt={review.affiliate.name} style={{ maxHeight: "100%", maxWidth: "100%" }} />

          {user.admin &&
            <>
              {review.oto_image &&
                <div className="d-flex align-items-center pointer mt-2" role="button" onClick={() => DeleteOTOImage()}>
                  <FontAwesomeIcon icon={faTrash} color="red" />
                  <h6 className="ml-2 mb-0 sm" style={{ color: "red" }}> Delete Bonus Image </h6>
                </div>
              }
              <div className="d-flex flex-column align-items-center">
                <div className="switch-field mt-2 w-100">
                  {review.affiliate_link_set.map((object, i) =>
                    renderLink(object, i)
                  )}
                </div>
                <p>{review.product.name} </p>
                <p>{review.video_title} </p>
              </div>


            </>
          }

          <Button className="mt-2 w-100" role="button" onClick={() => handleUserPopup()}><div>View Bonuses From</div> <div className="text-capitalize">{review.affiliate.name}</div></Button>

        </Card.Body>
      </Card >
      {/* </Link> */}
    </div >
  );
  // }
}

export default Review;

// Review.propTypes = {
//   review: PropTypes.object.isRequired
// };
// const mapStateToProps = state => ({
//   auth: state.auth,
// });

// export default connect(mapStateToProps, { })(
//   withRouter(Review)
// );
