import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';

import { Row, Button, Form } from "react-bootstrap";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import Sticky from 'react-sticky-el';
import ShowMoreText from "react-show-more-text";
import { Helmet } from "react-helmet";



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faSearchPlus, faSearchMinus, faArrowUp, faArrowDown, faArrowLeft, faArrowRight, faTimes, faArrowsAlt, faArrowsAltH, faArrowsAltV, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';


import { getReviews, saveProductImage, takeScreenshot, changeImageSource } from "./ReviewServices";
import Review from "./Review";
import { YoutubeEmbed, getYoutubeVidId } from "../../utils/YoutubeEmbed";



function ReviewList({ match }) {

  const [fullscreen, setFullscreen] = useState(true);
  const [userModalFullscreen, setUserModalFullscreen] = useState(false);
  const [show, setShow] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedAdminLink, setSelectedAdminLink] = useState(null);
  const [imageAdded, setImageAdded] = useState("pagescreen");


  
  const [reviews, setReviews] = useState([]);
  const [product, setProduct] = useState(false);
  const [linkid, setLinkId] = useState(0);
  const [reviewid, setReviewId] = useState(0);
  const [imagelink, setImageLink] = useState(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [viewMode, setViewMode] = useState(1);


  const user = useSelector((state) => state.auth.user);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setLinkId(id);
    let link = `${process.env.REACT_APP_API_URL}/media/screenshots/${id}_screenshot.jpg`;
    setImageLink(link);
  };

  // Cropper load image from clipboard on paste

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === 0) {
        const blob = items[i].getAsFile();
        const reader = new FileReader();
        reader.onload = (event) => {
          setImageLink(event.target.result);
          // cropperRef.current.replace(event.target.result);
        };
        reader.readAsDataURL(blob);
      }
    }
  };

  const handleCloseUser = () => setShowUserModal(false);
  const cropperRef = useRef(null);


  useEffect(() => {

    if (match.params.slug !== null) {
      const slug = match.params.slug;
      getReviews(slug).then((res) => {
        setReviews(res.reviews);
        setProduct(res.product);
      });
    } else {
      setReviews(match.params.reviews);
      setProduct(null);
    }
  }, [match.params.reviews])


  const saveCroppedImage = async (type) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    await cropper.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();
      formData.append("image", blob);
      // formData.append("product_id", product.id);
      formData.append("type", type);
      formData.append("review_id", reviewid);
      saveProductImage(formData).then((res) => {
        if (type === "product") {
          setProduct(product => ({ ...product, image: res.image }));
        } else {
          let newReviews = reviews.map((review) => {
            if (review.id === reviewid) {
              review.oto_image = res.image;
            }
            return review;
          });
          setReviews(newReviews);
        }
      });
      handleClose();
    }/*, 'image/png' */);
  }

  const removeOtoImage = (review_id) => {
    let newReviews = reviews.map((review) => {
      if (review.id === review_id) {
        review.oto_image = null;
      }
      return review;
    });
    setReviews(newReviews);
  }

  const TakeScreenshotHandle = (linkid, t) => {
    takeScreenshot(linkid, t).then((res) => {
      // console.log("Hi");
    });
  }
  
  const UpdateImageSource = (val, linkid) => {
    setImageAdded(val)
    selectedAdminLink.image_added = val == "system" ? true : false
    setSelectedAdminLink(selectedAdminLink)
    changeImageSource(linkid, val).then((res) => {
      // console.log("Hi");
    })
  }
  const getImageLink = ( linkid ) => {
    return `${process.env.REACT_APP_API_URL}/media/screenshots/${linkid}_screenshot.jpg`;
    
  }

  




  const onCropMove = (e) => {
    const cropper = cropperRef.current.cropper;
    const { width, height } = cropper.getCroppedCanvas();
    setHeight(height);
    setWidth(width);
  };

  const DeleteProductImage = () => {
    let data = {
      "id": product.id,
      "type": "delete_product_image"
    }
    axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/handle/`, data).then((res) => {
      setProduct(product => ({ ...product, image: null }));
    });
  }

  const recordClick = () => {
    console.log("clicked!")
  }

  const renderButton = (link, username) => {

    if (user.username && user.admin) {
      return (
        <a href={link[0].link} target="_blank" rel="noreferrer" >
          <Button className="text-capitalize">Get Bonuses From {username} {">>"}</Button>
        </a>
      );
    } else if (user.username) {
      return (
        <a href={link.link} target="_blank" rel="noreferrer" onClick={() => recordClick("")}>
          <Button className="text-capitalize">Get Bonuses From {username} {">>"}</Button>
        </a>
      );
    }
    else {
      return (
        <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/login`}>
          <Button className="m-3">Login or Signup</Button>
        </Link>
      );
    }
  }

  function replaceWithBr(text) {
    return text.replace(/\n/g, "<br />")
  }


  return (
    <>
      <Row>
        <div className="col-md-12 mb-2">
          {product &&
            <div>
              <Helmet>
                <title>{`${product.name} - ${product.vendor.name}`} | BonusReview.us</title>
                <meta property="og:title" content={`Best Bonuses - ${product.name} | BonusReview.us`} />
                <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/media/og.png`} />
              </Helmet>
              <Sticky stickyClassName={"product-sticky"}>
                <div id="second-top-bar" className="d-flex align-items-center justify-content-between bg-white p-3">
                  <div className="d-flex align-items-center mr-2">
                    <Link to={`/`}>
                      <img src={`${process.env.REACT_APP_API_URL}/media/logo-mini.png`}
                        className='animated-logo-on-sticky mr-3'
                        height="40"
                      />
                    </Link>
                    <h4 className="text-capitalize">{product.name}</h4>
                  </div>
                  <div className="vendor">
                    <p className="text-muted mb-0 mr-2">Vendor</p>
                    <Link className='d-flex align-items-center' style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/vendors-and-affiliates/${product.vendor.slug}`}>
                      <img className="vendor_image rounded-circle mr-2" src={product.vendor.image ? product.vendor.image : `${process.env.REACT_APP_API_URL}/media/d-vendor.png`} alt={product.vendor.name} />
                      <p className="vendor_name text-capitalize">{product.vendor.name}</p>
                    </Link>
                  </div>
                </div>
              </Sticky>
              {product.description && 
              <ShowMoreText
                lines={1}
                more="Show more"
                less="Show less"
                anchorClass=""
                expanded={false}
              >
                <p dangerouslySetInnerHTML={{ __html: replaceWithBr(product.description) }} ></p>

              </ShowMoreText>
              }
              {product.image &&
                <>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <img className="mb-3 top-product-image" src={process.env.REACT_APP_API_URL + product.image} alt={product.name} />
                    {user.admin &&
                      <div className="d-flex align-items-center pointer" role="button" onClick={() => DeleteProductImage()}>
                        <FontAwesomeIcon icon={faTrash} color="red" />
                        <h6 className="ml-2 mb-0 sm" style={{ color: "red" }}> Delete Product Image </h6>
                      </div>
                    }
                  </div>
                </>
              }
              {/* {productImage} */}
            </div>
          }
        </div>
      </Row>
      <Row>
        {(reviews.length > 0) ?
          reviews.map((review, index) => {
            return <Review key={index} review={review} user={user} handleAdminPopupShow={handleShow} setReviewIdChild={setReviewId} setShowUserModal={setShowUserModal} setSelectedReview={setSelectedReview} removeOtoImage={removeOtoImage} setSelectedAdminLink={setSelectedAdminLink} setImageAdded={setImageAdded} />
          })
          :
          <div className="col-md-12">
            <Skeleton count={2} />
          </div>
        }
      </Row>

      {/* Admin Modal */}
      <Modal
        show={show}
        onPaste={handlePaste}
        onHide={handleClose}
        fullscreen={fullscreen}
        dialogClassName="custom-modal"
        bsClass="my-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-6'>
            {selectedAdminLink &&
              <>
              <Form>
                  <div key={`1`} className="mb-3">
                    <Form.Check
                      inline
                      label="Use Pagescreen"
                      name="group1"
                      type="radio"
                      onChange={(e) => UpdateImageSource("pagescreen", selectedAdminLink.id)}
                      id={`1-1`}
                      checked={imageAdded == "pagescreen"}
                    />
                    <Form.Check
                      inline
                      value={true}
                      label="Use System screenshot"
                      name="group1"
                      type="radio"
                      id={`1-2`}
                      onChange={(e) => UpdateImageSource("system", selectedAdminLink.id)}
                      checked={imageAdded == "system"}
                    />
                  </div>
              </Form>
              <div className='flex align-items-center justify-between'>
                <Button variant="secondary" onClick={() => TakeScreenshotHandle(selectedAdminLink.id, "pagescreen")}>
                    Retake
                </Button>
              </div>
              <img src={selectedAdminLink.image_link } style={{width:"inherit"}} />
              </> 
            }
            </div>
            <div className='col-md-6'>
            {selectedAdminLink  &&
              <>
              <div className='flex align-items-center justify-between'>
                <Button variant="secondary" onClick={() => TakeScreenshotHandle(selectedAdminLink.id, "system")} >
                  Retake
                </Button>
              </div>

              <img src={imagelink} style={{width:"inherit"}} />
              </>
            }
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <a href={imagelink} style={{ color: 'inherit', textDecoration: 'inherit' }} target="_blank">
            <Button variant="primary" style={{ backgroundColor: 'white', color: 'black' }}>
              Open Image
            </Button>
          </a>
          {/* <Button variant="primary" onClick={() => { saveCroppedImage("product"); }}>
            Save As Product Image
          </Button> */}
          <Button variant="primary" onClick={() => { saveCroppedImage("oto"); }}>
            Save As Bonus Image
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* User Modal */}
      <Modal
        id="user-modal"
        show={showUserModal}
        onHide={handleCloseUser}
        size="lg"
        dialogClassName={userModalFullscreen ? "custom-modal" : ""}
      >
        {selectedReview &&
          <>
            <Modal.Header>
              <div className='d-flex align-items-center justify-content-between w-100'>
                <Modal.Title>
                  <div className="d-flex align-items-center mr-2">
                    <Link to={`/`}>
                      <img src={`${process.env.REACT_APP_API_URL}/media/logo-mini.png`}
                        className=' mr-3'
                        height="40"
                      />
                    </Link>
                    <div className='d-flex flex-column'>
                      <h4 className='text-capitalize mb-0'>{selectedReview.affiliate.name}'s Review & Bonuses</h4>
                      <Link to={`/product/${selectedReview.product.slug}`} target="_blank">
                        {selectedReview.product.name}
                      </Link>
                    </div>
                  </div>

                </Modal.Title>
                <div>
                  {/* Checkbox to toggle modal fullscreen */}
                  <div className='d-flex align-items-center' id="hide-mobile" >
                    {userModalFullscreen ?
                      <FontAwesomeIcon role="button" icon={faCompress} size="2x" color="black" onClick={() => setUserModalFullscreen(false)} />
                      :
                      <FontAwesomeIcon role="button" icon={faExpand} size="2x" color="black" onClick={() => setUserModalFullscreen(true)} />
                    }

                    <FontAwesomeIcon role="button" className='ml-5 mr-5' size="2x" icon={faTimes} color="black" onClick={() => setShowUserModal(false)} />


                  </div>
                  {/* <input type="checkbox"
                    value="fullscreen"
                    onChange={() => setUserModalFullscreen(!userModalFullscreen)} /> */}
                </div>
              </div>
            </Modal.Header>
            <Modal.Body style={{
              maxHeight: 'calc(100vh - 110px)',
              overflowY: 'auto'
            }}>
              <div className="row">
                <div className="col-md-12">
                  {user.username &&
                    <YoutubeEmbed embedId={getYoutubeVidId(selectedReview.video_link)} />
                  }
                  {!user.username &&
                    <div className='text-center'>
                      <img src={`https://i.ytimg.com/vi/${getYoutubeVidId(selectedReview.video_link)}/mqdefault.jpg`} alt={selectedReview.affiliate.name} style={{ maxHeight: "100%", maxWidth: "100%", textAlign: "center" }} />
                    </div>
                  }
                  {user.admin &&
                    <div className='mt-2'>
                      {/* <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        anchorClass=""
                        expanded={false}
                        width={280}
                      > */}
                      <p dangerouslySetInnerHTML={{ __html: replaceWithBr(selectedReview.description) }} ></p>
                      {/* </ShowMoreText> */}
                    </div>
                  }
                  <div className="text-center mt-2 p-4">

                    {(user.username) ?
                      <div className="d-flex align-items-center justify-content-center">
                        {/* {(selectedReview.affiliate_link_set.image_added) ? 
                          <img width="100%" 
                            className="mb-3 mt-3" 
                            src={getImageLink(selectedReview.affiliate_link_set.id)} 
                            alt={selectedReview.name} 
                            onError={(e) => {
                              e.target.src = selectedReview.affiliate_link_set.image_link // some replacement image
                            }}
                          />
                            :
                          <img width="100%" className="mb-3 mt-3" src={selectedReview.affiliate_link_set.image_link} alt={selectedReview.name} />
                          } */}
                          <img width="100%" className="mb-3 mt-3" src={selectedReview.affiliate_link_set.image_link} alt={selectedReview.name} />
                      </div>
                      :
                      null}

                    {/* {(selectedReview.oto_image) ?
                      <div className="d-flex align-items-center justify-content-center">
                        <img width="100%" className="mb-3 mt-3" src={process.env.REACT_APP_API_URL + "/media/" + selectedReview.oto_image} alt={selectedReview.name} />
                      </div>
                      : (user.username) ?
                        <div className="d-flex align-items-center justify-content-center">
                          <img width="100%" className="mb-3 mt-3" src={process.env.REACT_APP_API_URL + "/media/screenshots/" + (selectedReview.affiliate_link_set.length > 1 ? selectedReview.affiliate_link_set[0].id : selectedReview.affiliate_link_set.id) + "_screenshot.jpg"} alt={selectedReview.name} />
                        </div>
                        :
                        null
                    } */}

                    {/* <h3>{selectedReview.affiliate.name}</h3> */}


                    {!user.username &&
                      <>
                        <h4>Please sign in to see the bonuses and to access the review link for {selectedReview.affiliate.name}</h4><br></br>
                        <h4>If you do not have an account you can <Link to={`/signup`}>sign up for FREE!</Link></h4>
                      </>
                    }
                  </div>
                </div>
              </div>

            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "space-between", flexWrap: "unset" }}>
              <div>
              </div>

              {renderButton(selectedReview.affiliate_link_set, selectedReview.affiliate.name)}
              <Button variant="secondary" onClick={handleCloseUser}>
                Close
              </Button>
            </Modal.Footer>
          </>
        }
      </Modal>
    </>
  );
  // }
}

export default ReviewList;