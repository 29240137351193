import axios from "axios";

export async function getProducts(page = 1, ordering = "-launch_date") {
    try {
        ordering = ordering.endsWith("launch_date") ? "-status__name," + ordering : ordering;

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/products/?page=${page}&ordering=${ordering}`);
        let result = await response.data;
        return result;
    } catch (error) {
        return [];
    }
}