import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


import { Row, Button } from "react-bootstrap";
import Sticky from 'react-sticky-el';

import { getUserProducts } from "./UserProductServices";
import Product from "../products/Product";
import ReviewList from '../reviews/ReviewList';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from "react-helmet";


function UserProductsList({ match }) {

  const user = useSelector((state) => state.auth.user);
  const [products, setProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [person, setPerson] = useState(null);

  useEffect(() => {
    const slug = match.params.slug;
    getUserProducts(slug).then((result) => {
      setProducts(result.products);
      setReviews(result.reviews);
      setPerson(result.user);
    });
  }, [])

  return (
    <>
      <Row>
        <div className="col-md-12 mb-2">
          {person &&
            <div>
              <Helmet>
                <title>{`BonusReview.us - ${person.name}`}</title>
                <meta property="og:title" content={`${person.name} | BonusReview.us`} />
                <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/media/og.png`} />
              </Helmet>
              <Sticky stickyClassName={"product-sticky"}>
                <div className="d-flex align-items-center justify-content-between bg-white p-3">
                  <div className="d-flex align-items-center mr-2">
                    <Link to={`/`}>
                      <img src={`${process.env.REACT_APP_API_URL}/media/logo-mini.png`}
                        className='animated-logo-on-sticky mr-3'
                        height="40"
                      />
                    </Link>
                    <span className='d-flex'>
                      <img className="vendor_image rounded-circle mr-2" src={person.image ? person.image : `${process.env.REACT_APP_API_URL}/media/d-vendor.png`} alt={person.name} />
                      <h4>{person.name}</h4>
                    </span>

                  </div>
                  {/* <div className="vendor">
                      <p className="text-muted mb-0 mr-2">Vendor</p>
                      <img className="vendor_image rounded-circle mr-2" src={vendor_image} alt={product.vendor.name} />
                      <p className="vendor_name">{product.vendor.name}</p>
                    </div> */}
                </div>
              </Sticky>
            </div>
          }
        </div>
      </Row>

      {
        ((products.length === 0) && (reviews.length === 0)) ?
          <div className="row">
            <div className="col-md-12">
              <Skeleton count={2} />
            </div>
          </div>
          : null
      }

      {products.length > 0 &&
        <div className="row">
          <h4 className="col-md-12 mb-2">Products</h4>
          {products.length && products.map((product) => (
            <Product key={product.id} product={product} user={user} />
          ))}
        </div>
      }
      {reviews.length > 0 &&
        <>
          <div className="row">
            <h4 className="col-md-12 mb-2">Reviews</h4>
          </div>
          <ReviewList match={{ params: { reviews: reviews, slug: null } }} />
        </>
      }
    </>
  );
}

export default UserProductsList;